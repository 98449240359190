<template>
  <div>
    <c-card title="LBLBASEINFO" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant 
            :disabled="popupParam.isOld"
            :editable="editable"
            type="edit"
            name="plantCd" 
            v-model="popupParam.plantCd"
            @datachange="datachange" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :disabled="popupParam.isOld"
            :editable="editable"
            type="year"
            label="년도"
            name="year"
            v-model="popupParam.year"
            @datachange="datachange"
          />
        </div>
      </template>
    </c-card>
    <!-- :data="filterData" -->
    <c-table
      ref="inout-table"
      class="q-mt-sm"
      :title="`${popupParam.year}년도 제조/수입/사용/판매 관리대장`"
      :editable="editable"
      :columns="gridColumns"
      :data="grid.data"
      :gridHeight="gridHeight"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="배출량 조사대상 화학물질 엑셀 업로드" icon="note_add" @btnClicked="openExcelUploader" />
        </q-btn-group>
      </template>
      <!-- 데이터가 많지 않은 관계로 필터링 X -->
      <!-- <template slot="suffixTitle">
        <c-datepicker
          class="inout-facility"
          type="month"
          default="today"
          label=""
          name="month"
          v-model="filter.month"
        />
      </template> -->
      <template v-slot:customBottomRow>
        <q-tr 
          v-if="grid.data && grid.data.length > 0" 
          no-hover
          class="table-bottom-fixed">
          <q-td
            class="text-center th-td-style th-style"
            :rowspan="1" :colspan="1">
          </q-td>
          <q-td
            class="text-center th-td-style th-style"
            :rowspan="1" :colspan="1">
          </q-td>
          <q-td
            class="text-right th-td-style th-style"
            :rowspan="1" :colspan="1">
          </q-td>
          <q-td
            class="text-right th-td-style th-style"
            :rowspan="1" :colspan="1">
            <span>
              {{ $_.round($_.sumBy(grid.data, 'inAmountBefore'), 2) | toThousandFilter }}
            </span>
          </q-td>
          <q-td
            class="text-center th-td-style th-style"
            :rowspan="1" :colspan="1">
          </q-td>
          <q-td
            class="text-right th-td-style th-style"
            :rowspan="1" :colspan="1">
            <span>
              {{ $_.round($_.sumBy(grid.data, 'inAmount'), 2) | toThousandFilter }}
            </span>
          </q-td>
          <q-td
            class="text-center th-td-style th-style"
            :rowspan="1" :colspan="1">
          </q-td>
          <q-td
            class="text-center th-td-style th-style"
            :rowspan="1" :colspan="1">
          </q-td>
          <q-td
            class="text-center th-td-style th-style"
            :rowspan="1" :colspan="1">
          </q-td>
          <q-td
            class="text-right th-td-style th-style"
            :rowspan="1" :colspan="1">
            <span>
              {{ $_.round($_.sumBy(grid.data, 'outAmountBefore'), 2) | toThousandFilter }}
            </span>
          </q-td>
          <q-td
            class="text-right th-td-style th-style"
            :rowspan="1" :colspan="1">
            <span>
              {{ $_.round($_.sumBy(grid.data, 'outAmount'), 2) | toThousandFilter }}
            </span>
          </q-td>
          <q-td
            class="text-center th-td-style th-style"
            :rowspan="1" :colspan="1">
          </q-td>
        </q-tr>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'inout-management-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        plantCd: '',
        year: '',
        isOld: false,
      }),
    },
    contentHeight: {
      type: [Number, String],
      default: function() {
        return null;
      },
    },
  },
  data() {
    return {
      // filter: {
      //   month: null,
      // },
      grid: {
        data: [],
      },
      editable: true,
      listUrl: '',
      validUrl: '',
      saveUrl: '',
      popupOptions: {
        isFull: false,
        width: '80%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      let height = this.contentHeight - 170;
      if (height < 500) {
        height = 500;
      }
      return String(height) + 'px';
    },
    gridColumns() {
      return [
        {
          name: 'inoutDate',
          field: 'inoutDate',
          label: '입고일',
          align: 'center',
          style: 'width:120px',
          sortable: false,
          start: `${this.popupParam.year}-01-01`,
          end: `${this.popupParam.year}-12-31`,
        },
        {
          name: 'materialCd',
          field: 'materialCd',
          label: '자재코드',
          align: 'center',
          style: 'width:100px',
          sortable: false,
        },
        {
          name: 'materialName',
          field: 'materialName',
          label: '자재명',
          align: 'left',
          sortable: false,
        },
        {
          name: 'in',
          field: 'in',
          label: '입고',
          align: 'center',
          child: [
            {
              name: 'inAmountBefore',
              field: 'inAmountBefore',
              label: '입고수량(원본)',
              align: 'right',
              style: 'width:130px',
              sortable: false,
              type: 'cost'
            },
            {
              name: 'facilityCd',
              field: 'facilityCd',
              label: '환산계수',
              align: 'right',
              style: 'width:80px',
              sortable: false,
              type: 'cost'
            },
            {
              name: 'inAmount',
              field: 'inAmount',
              label: '입고수량(환산)',
              align: 'right',
              style: 'width:130px',
              sortable: false,
              type: 'cost'
            },
            {
              name: 'facilityName',
              field: 'facilityName',
              label: '환산단위',
              align: 'center',
              style: 'width:70px',
              sortable: false,
            },
            {
              name: 'inVendorCd',
              field: 'inVendorName',
              label: '협력사',
              align: 'center',
              style: 'width:120px',
              sortable: false,
            },
            {
              name: 'inVendorName',
              field: 'inVendorName',
              label: '협력사명',
              align: 'center',
              style: 'width:220px',
              sortable: false,
            },
          ]
        },
        {
          name: 'outAmountBefore',
          field: 'outAmountBefore',
          label: '출고량(원본)',
          align: 'right',
          style: 'width:130px',
          sortable: false,
          type: 'cost'
        },
        {
          name: 'outAmount',
          field: 'outAmount',
          label: '출고량(환산)',
          align: 'right',
          style: 'width:130px',
          sortable: false,
          type: 'cost'
        },
        {
          name: 'remarks',
          field: 'remarks',
          label: '발주유형명',
          align: 'left',
          style: 'width:150px',
          sortable: false,
        },
      ]
    },
    // filterData() {
    //   return this.grid.data.filter(
    //     data =>
    //       !this.filter.month
    //       || data.yearmonth.toLowerCase().includes(this.filter.month.toLowerCase())
    //   )
    // },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.chm.inout.list.url;
      this.validUrl = selectConfig.chm.inout.valid.url;
      this.saveUrl = transactionConfig.chm.inout.save.url;
      // code setting
      // list setting
      console.log('this.popupParam.year',this.popupParam.year)
      this.getList();
    },
    getList() {
      if (this.popupParam.plantCd && this.popupParam.year) {
        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          plantCd: this.popupParam.plantCd,
          year: this.popupParam.year
        }
        this.$http.request((_result) => {
          this.grid.data = _result.data;
        },);
      }
    },
    datachange() {
      if (this.popupParam.plantCd && this.popupParam.year && !this.popupParam.isOld) {
        this.$http.url = this.validUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          plantCd: this.popupParam.plantCd,
          year: this.popupParam.year
        }
        this.$http.request((_result) => {
          if (_result.data > 0) {
            window.getApp.$emit('ALERT', {
              title: '안내 ', /* 안내 */
              message: '입력한 사업장과 년도에 이미 데이터가 존재합니다.\n[년도] 데이터를 다시 입력하세요.',
              type: 'warning', // success / info / warning / error
            });
            this.popupParam.year = null;
          } else {
            // nothing..
          }
        },);

      }
    },
    openExcelUploader() {
      if (!this.popupParam.plantCd || !this.popupParam.year) {
        window.getApp.$emit('ALERT', {
          title: '안내 ', /* 안내 */
          message: '사업장과 년도를 입력 후 이용바랍니다.',
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      this.popupOptions.title = '화학물질 관리대장 엑셀 업로드';
      this.popupOptions.target = () => import(`${'./inoutManagementExcelUpload.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeExcelUploaderPopup;
    },
    closeExcelUploaderPopup(_result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (_result && _result.length > 0) {
        let s_data = this.$_.filter(_result, (item) => { return !item.error_message })
        this.$_.forEach(s_data, item => {
          // item.plantCd = this.popupParam.plantCd;
          // item.year = this.popupParam.year;
          item.regUserId = this.$store.getters.user.userId;
          item.editFlag = 'C';
        })

        this.$http.url = this.saveUrl;
        this.$http.type = 'PUT';
        this.$http.param = {
          plantCd: this.popupParam.plantCd,
          year: this.popupParam.year,
          inouts: s_data
        }
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getList();
        },);
      }
    },
  }
};
</script>
<style lang="sass">
.chem-inout-info 
  margin-top: -20px !important
.inout-facility
  display: inline-block
  label
    padding-bottom: 0px !important
  .q-field__inner
    padding-bottom: 0px !important
  .mx-input-wrapper
    margin: 0 !important
</style>